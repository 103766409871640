import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";

export const PAYMENT_TYPE_CASH = 1;
export const PAYMENT_TYPE_BANK = 2;
export const PAYMENT_TYPE_LIVE_PAY = 3;

export const PAYMENT_TYPES: Array<IBasicNamedDTO> = [
  {
    id: PAYMENT_TYPE_CASH,
    name: "Bar",
  },
  {
    id: PAYMENT_TYPE_BANK,
    name: "Bank",
  },
  {
    id: PAYMENT_TYPE_LIVE_PAY,
    name: "LivePay",
  },
];
