



























import { Component, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { downloadFile } from "@/utils/File";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";

const ModuleExport = namespace("report");
@Component({
  components: { PrintButton, Datepicker, Validation, FscSimpleCard },
})
export default class ExportServiceList extends Vue {
  public name = "ExportServiceList";

  public fromDate = null;
  public toDate = null;

  @ModuleExport.Mutation("export/IS_LOADING")
  protected setIsLoading: any;

  @ModuleExport.Getter("export/getIsLoading")
  protected getIsLoading: any;

  @Validations()
  public validations(): Record<string, any> {
    return {
      fromDate: {
        required,
      },
      toDate: {
        required,
      },
    };
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.setIsLoading(true);
    downloadFile(
      {
        url: `/reports/driving-school-service-overview/csv?fromDate=${this.fromDate}&untilDate=${this.toDate}`,
        method: "post",
      },
      `export.csv`
    )
      .then((res: any) => {
        this.setIsLoading(false);
      })
      .catch((er: any) => {
        this.setIsLoading(false);
      });
  }
}
