


















































import { Component, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { INVOICE_TYPES_WITHOUT_CANCELLATION_INVOICE } from "@/constants/InvoiceType";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { downloadFile } from "@/utils/File";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";

const ModuleExport = namespace("report");

@Component({
  components: { PrintButton, Datepicker, Validation, FscSimpleCard },
})
export default class ExportStudentsInvoices extends Vue {
  public name = "ExportStudentsInvoices";

  @ModuleExport.Mutation("export/IS_LOADING")
  protected setIsLoading: any;

  @ModuleExport.Getter("export/getIsLoading")
  protected getIsLoading: any;

  public invoiceTypes: Array<number> = [];
  public fileType = "csv";
  public fromDate = null;
  public toDate = null;

  public get invoiceTypesOptions(): Array<IBasicNamedDTO> {
    return INVOICE_TYPES_WITHOUT_CANCELLATION_INVOICE.reduce(
      (previousValue: Array<IBasicNamedDTO>, currentValue: IBasicNamedDTO) => {
        previousValue.push(currentValue);
        return previousValue;
      },
      [
        {
          id: 0,
          name: this.$t("todo.all").toString(),
        },
      ]
    );
  }

  private filesTypesOptions = [
    { text: "CSV", value: "csv" },
    { text: "PDF", value: "pdf" },
  ];

  @Validations()
  public validations(): Record<string, any> {
    return {
      fromDate: {
        required,
      },
      toDate: {
        required,
      },
      invoiceTypes: {
        required,
      },
    };
  }

  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.setIsLoading(true);
    downloadFile(
      {
        url: `/reports/invoices/${this.fileType}`,
        method: "post",
        data: {
          fromDate: this.fromDate,
          toDate: this.toDate,
          invoiceTypes: this.invoiceTypesFilter,
        },
      },
      `export.${this.fileType}`
    )
      .then(() => {
        this.setIsLoading(false);
      })
      .catch(() => {
        this.setIsLoading(false);
      });
  }

  public get invoiceTypesFilter() {
    return this.invoiceTypes.includes(0) ? [100, 200, 400] : this.invoiceTypes;
  }

  public selectableInvoiceOption(option: IBasicNamedDTO) {
    if (this.invoiceTypes.includes(0) && option.id !== 0) {
      return false;
    } else if (this.invoiceTypes.length && !this.invoiceTypes.includes(0) && option.id === 0) {
      return false;
    }
    return true;
  }
}
