



























import { Component, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import { downloadFile } from "@/utils/File";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";

const ModuleExport = namespace("report");

@Component({
  components: { PrintButton, Datepicker, FscSimpleCard },
})
export default class ExportStudentsBalanceOverview extends Vue {
  public name = "ExportStudentsBalanceOverview";
  @ModuleExport.Mutation("export/IS_LOADING")
  protected setIsLoading: any;

  @ModuleExport.Getter("export/getIsLoading")
  protected getIsLoading: any;

  public fileType = "csv";
  public untilDate = null;

  private filesTypesOptions = [
    { text: "CSV", value: "csv" },
    { text: "PDF", value: "pdf" },
  ];

  public onSubmit(): void {
    let params = "";

    if (this.untilDate) {
      params = `?untilDate=${this.untilDate}`;
    }

    this.setIsLoading(true);
    downloadFile(
      {
        url: `/reports/students-balance-overview/${this.fileType}${params}`,
        method: "post",
      },
      `export.${this.fileType}`
    )
      .then(() => {
        this.setIsLoading(false);
      })
      .catch(() => {
        this.setIsLoading(false);
      });
  }
}
