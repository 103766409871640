



























import { Vue, Component } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { downloadFile } from "@/utils/File";

@Component({
  components: { Datepicker, PrintButton, Validation, FscSimpleCard },
})
export default class ExportBookingCompensation extends Vue {
  public name = "ExportBookingCompensation";

  private fromDate = null;
  private toDate = null;

  private loading = false;

  @Validations()
  public validations(): Record<string, any> {
    return {
      fromDate: {
        required,
      },
      toDate: {
        required,
      },
    };
  }
  public onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    this.loading = true;
    downloadFile(
      {
        url: `/reports/chargeback-export/pdf?from=${this.fromDate}&to=${this.toDate}`,
        method: "get",
      },
      `export.pdf`
    ).finally(() => {
      this.loading = false;
    });
  }
}
