var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.getIsLoading,"rounded":"sm"}},[_c('FscSimpleCard',{staticClass:"h-100 overflow-y-auto",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"btn-container"},[_c('PrintButton',{staticClass:"fsc-btn-border ml-2",on:{"click":_vm.onSubmit}})],1)]},proxy:true}])},[_c('b-form',{staticClass:"row"},[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" Von *")]),_c('Validation',{attrs:{"validations":_vm.$v.fromDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid }},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" Bis *")]),_c('Validation',{attrs:{"validations":_vm.$v.toDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid }},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.invoice_type", 1))+" *")]),_c('Validation',{attrs:{"validations":_vm.$v.invoiceTypes},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"multiple":true,"options":_vm.invoiceTypesOptions,"label":"name","reduce":function (opt) { return opt.id; },"selectable":_vm.selectableInvoiceOption},model:{value:(_vm.invoiceTypes),callback:function ($$v) {_vm.invoiceTypes=$$v},expression:"invoiceTypes"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$tc("general.file_type", 1)))]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"options":_vm.filesTypesOptions,"aria-describedby":ariaDescribedby,"name":"file_type"},model:{value:(_vm.fileType),callback:function ($$v) {_vm.fileType=$$v},expression:"fileType"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }